import { render, staticRenderFns } from "./teltonika-gps.com-landing.vue?vue&type=template&id=c5a6dadc&scoped=true"
import script from "./teltonika-gps.com-landing.vue?vue&type=script&lang=js"
export * from "./teltonika-gps.com-landing.vue?vue&type=script&lang=js"
import style0 from "./teltonika-gps.com-landing.vue?vue&type=style&index=0&id=c5a6dadc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@3.2.5_vue-temp_n4gdrmfirpmgaryqotnkfbokze/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5a6dadc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroTwentyTwo: require('/src/components/hero/HeroTwentyTwo.vue').default,SectionImageWithFeatures: require('/src/components/section/ImageWithFeatures.vue').default,SectionImageWithFeaturesMobile: require('/src/components/section/ImageWithFeaturesMobile.vue').default,SectionProductTabs: require('/src/components/section/ProductTabs.vue').default,SectionImageLabelTitleContentFourFeaturesButton: require('/src/components/section/ImageLabelTitleContentFourFeaturesButton.vue').default,SectionJournalsPdf: require('/src/components/section/JournalsPdf.vue').default,SectionExpandableStatistics: require('/src/components/section/ExpandableStatistics.vue').default,InfinityLoader: require('/src/components/InfinityLoader.vue').default,TelematicsSectionsHeader: require('/src/components/telematics/SectionsHeader.vue').default,SectionNewsletterImageTitleContentButton: require('/src/components/section/NewsletterImageTitleContentButton.vue').default,CarouselOne: require('/src/components/carousel/CarouselOne.vue').default})
