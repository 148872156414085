
export default {
  name: 'ContentExpandSimple',
  props: {
    initiallyExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
    }
  },
  created() {
    this.expanded = this.initiallyExpanded
  },
}
