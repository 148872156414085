
import VClamp from 'vue-clamp'
import { getDateDistance } from '~/utils'
export default {
  components: {
    'v-clamp': VClamp,
  },
  props: {
    link: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    branch: {
      type: String,
      default: '',
    },
    excerpt: {
      type: String,
      default: '',
    },
    logo: {
      type: String,
      default: '',
    },
    image: {
      type: [String, Object],
      default: '',
    },
    isStatic: {
      type: Boolean,
      default: true,
    },
    contentStyles: {
      type: [String, Array],
      default: '',
    },
  },
  computed: {
    formattedDate() {
      return getDateDistance(
        this.date,
        this.$store.state['date-fns'].activeLocale,
      )
    },
    formattedLink() {
      if (this.isStatic) return this.link
      return `https://teltonika-iot-group.com${this.link}` || ''
    },
  },
}
