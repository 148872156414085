
import { VueAgile } from 'vue-agile'
export default {
  name: 'CarouselOne',
  components: {
    VueAgile,
    'v-clamp': () => import('vue-clamp'),
  },
  props: {
    news: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      slideOptions: {
        navButtons: false,
        dots: false,
        slidesToShow: 2,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 15000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              infinite: true,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              navButtons: true,
            },
          },
        ],
      },
    }
  },
}
