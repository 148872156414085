
export default {
  name: 'ProductTabs',
  props: {
    main: {
      type: Object,
      default: () => {},
    },
    tabs: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      activeItem: null,
    }
  },
  mounted() {
    this.activeItem = this.tabs[0]
  },
  methods: {
    handleClick(item) {
      this.activeItem = this.tabs.find((tab) => tab.title === item)
    },
  },
}
