
export default {
  name: 'JournalsPdf',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
