import { render, staticRenderFns } from "./CarouselOne.vue?vue&type=template&id=98fd3e24"
import script from "./CarouselOne.vue?vue&type=script&lang=js"
export * from "./CarouselOne.vue?vue&type=script&lang=js"
import style0 from "./CarouselOne.vue?vue&type=style&index=0&id=98fd3e24&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@3.2.5_vue-temp_n4gdrmfirpmgaryqotnkfbokze/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardsIotNews: require('/src/components/cards/IotNews.vue').default})
