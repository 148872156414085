import { render, staticRenderFns } from "./ExpandableStatistics.vue?vue&type=template&id=287787e3&scoped=true"
import script from "./ExpandableStatistics.vue?vue&type=script&lang=js"
export * from "./ExpandableStatistics.vue?vue&type=script&lang=js"
import style0 from "./ExpandableStatistics.vue?vue&type=style&index=0&id=287787e3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@3.2.5_vue-temp_n4gdrmfirpmgaryqotnkfbokze/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "287787e3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TelematicsSectionsHeader: require('/src/components/telematics/SectionsHeader.vue').default})
