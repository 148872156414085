
export default {
  name: 'NewsletterImageTitleContentButton',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
