
export default {
  name: 'ImageWithFeaturesMobile',
  props: {
    main: {
      type: Object,
      default: () => {},
    },
    features: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: Object,
      default: () => {},
    },
  },
}
