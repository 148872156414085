
export default {
  name: 'ExpandableStatistics',
  props: {
    main: {
      type: Object,
      default: () => {},
    },
    cards: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      activeItem: null,
    }
  },
  methods: {
    toggleContent(index) {
      if (this.activeItem === index) {
        this.activeItem = null
        return
      }

      this.activeItem = index
    },
  },
}
