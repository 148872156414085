
import { ContactsQuery } from '@/queries/contacts.gql'
import { formatRawMapResponse } from '@/utils'
import { newsPosts } from '@/queries/news/newsPosts.gql'

export default {
  name: 'GpsLanding',
  async asyncData({ app, i18n, $config, $sentry }) {
    try {
      const { data } = await app.$gqlQuery({
        query: newsPosts,
        fetchPolicy: 'no-cache',
        variables: {
          languageCode: i18n.locale,
          domainId: $config.domainId,
          postsLimit: 5,
        },
      })
      return {
        news: data?.newsPosts?.data || [],
      }
    } catch (error) {
      $sentry.captureException(error)
      console.error('error.graphQLErrors', error.graphQLErrors)
    }
  },
  data() {
    return {
      countries: false,
      news: [],
      pageFiles: {},
    }
  },
  computed: {
    hero() {
      return [
        {
          id: 1,
          title: this.$i18n.t('telematics.landing.hero-slide1.title'),
          content: this.$i18n.t('telematics.landing.hero-slide1.content'),
          ctaText: this.$i18n.te('telematics.landing.hero-slide1.ctaText')
            ? this.$i18n.t('telematics.landing.hero-slide1.ctaText')
            : '',
          ctaLink: this.$i18n.te('telematics.landing.hero-slide1.ctaLink')
            ? this.$i18n.t('telematics.landing.hero-slide1.ctaLink')
            : '',
          ctaContactUsText: this.$i18n.te(
            'telematics.landing.hero-slide1.ctaContactUsText',
          )
            ? this.$i18n.t('telematics.landing.hero-slide1.ctaContactUsText')
            : '',
          externalLinkText: this.$i18n.te(
            'telematics.landing.hero-slide1.externalLinkText',
          )
            ? this.$i18n.t('telematics.landing.hero-slide1.externalLinkText')
            : '',
          externalLink: this.$i18n.te(
            'telematics.landing.hero-slide1.externalLink',
          )
            ? this.$i18n.t('telematics.landing.hero-slide1.externalLink')
            : '',
          image: this.pageFiles['telematics.landing.hero-background1'],
        },
        {
          id: 2,
          title: this.$i18n.t('telematics.landing.hero-slide2.title'),
          content: this.$i18n.t('telematics.landing.hero-slide2.content'),
          ctaText: this.$i18n.te('telematics.landing.hero-slide2.ctaText')
            ? this.$i18n.t('telematics.landing.hero-slide2.ctaText')
            : '',
          ctaLink: this.$i18n.te('telematics.landing.hero-slide2.ctaLink')
            ? this.$i18n.t('telematics.landing.hero-slide2.ctaLink')
            : '',
          ctaContactUsText: this.$i18n.te(
            'telematics.landing.hero-slide2.ctaContactUsText',
          )
            ? this.$i18n.t('telematics.landing.hero-slide2.ctaContactUsText')
            : '',
          externalLinkText: this.$i18n.te(
            'telematics.landing.hero-slide2.externalLinkText',
          )
            ? this.$i18n.t('telematics.landing.hero-slide2.externalLinkText')
            : '',
          externalLink: this.$i18n.te(
            'telematics.landing.hero-slide2.externalLink',
          )
            ? this.$i18n.t('telematics.landing.hero-slide2.externalLink')
            : '',
          image: this.pageFiles['telematics.landing.hero-background2'],
        },
        {
          id: 3,
          title: this.$i18n.t('telematics.landing.hero-slide3.title'),
          content: this.$i18n.t('telematics.landing.hero-slide3.content'),
          ctaText: this.$i18n.te('telematics.landing.hero-slide3.ctaText')
            ? this.$i18n.t('telematics.landing.hero-slide3.ctaText')
            : '',
          ctaLink: this.$i18n.te('telematics.landing.hero-slide3.ctaLink')
            ? this.$i18n.t('telematics.landing.hero-slide3.ctaLink')
            : '',
          ctaContactUsText: this.$i18n.te(
            'telematics.landing.hero-slide3.ctaContactUsText',
          )
            ? this.$i18n.t('telematics.landing.hero-slide3.ctaContactUsText')
            : '',
          externalLinkText: this.$i18n.te(
            'telematics.landing.hero-slide3.externalLinkText',
          )
            ? this.$i18n.t('telematics.landing.hero-slide3.externalLinkText')
            : '',
          externalLink: this.$i18n.te(
            'telematics.landing.hero-slide3.externalLink',
          )
            ? this.$i18n.t('telematics.landing.hero-slide3.externalLink')
            : '',
          image: this.pageFiles['telematics.landing.hero-background3'],
        },
      ]
    },
    features() {
      return {
        main: {
          title: this.$i18n.t('telematics.landing.features.main.title'),
          link: this.$i18n.t('telematics.landing.features.main.link'),
          linkText: this.$i18n.t('telematics.landing.features.main.linkText'),
        },
        content: [
          {
            id: 1,
            title: this.$i18n.t('telematics.landing.features.feature1.title'),
            icon: 'features/shield',
            content: this.$i18n.t(
              'telematics.landing.features.feature1.content',
            ),
            links: [
              {
                id: 1,
                label: this.$i18n.t(
                  'telematics.landing.features.feature1.link1.label',
                ),
                link: this.$i18n.t(
                  'telematics.landing.features.feature1.link1.link',
                ),
              },
              {
                id: 2,
                label: this.$i18n.t(
                  'telematics.landing.features.feature1.link2.label',
                ),
                link: this.$i18n.t(
                  'telematics.landing.features.feature1.link2.link',
                ),
              },
            ],
            image: this.pageFiles['telematics.landing.feature-image1'],
            imageText: this.$i18n.t(
              'telematics.landing.features.feature1.imageText',
            ),
            imageIcon: 'globals/lock',
            imageAlt: 'gps tracker app',
          },
          {
            id: 2,
            title: this.$i18n.t('telematics.landing.features.feature2.title'),
            icon: 'features/pin_plus',
            content: this.$i18n.t(
              'telematics.landing.features.feature2.content',
            ),
            links: [
              {
                id: 1,
                label: this.$i18n.t(
                  'telematics.landing.features.feature2.link1.label',
                ),
                link: this.$i18n.t(
                  'telematics.landing.features.feature2.link1.link',
                ),
              },
              {
                id: 2,
                label: this.$i18n.t(
                  'telematics.landing.features.feature2.link2.label',
                ),
                link: this.$i18n.t(
                  'telematics.landing.features.feature2.link2.link',
                ),
              },
            ],
            image: this.pageFiles['telematics.landing.feature-image2'],
            imageText: this.$i18n.t(
              'telematics.landing.features.feature2.imageText',
            ),
            imageIcon: 'globals/track-changes',
            imageAlt: 'vehicle tracking',
          },
          {
            id: 3,
            title: this.$i18n.t('telematics.landing.features.feature3.title'),
            icon: 'globals/truck-full',
            content: this.$i18n.t(
              'telematics.landing.features.feature3.content',
            ),
            links: [
              {
                id: 1,
                label: this.$i18n.t(
                  'telematics.landing.features.feature3.link1.label',
                ),
                link: this.$i18n.t(
                  'telematics.landing.features.feature3.link1.link',
                ),
              },
              {
                id: 2,
                label: this.$i18n.t(
                  'telematics.landing.features.feature3.link2.label',
                ),
                link: this.$i18n.t(
                  'telematics.landing.features.feature3.link2.link',
                ),
              },
            ],
            image: this.pageFiles['telematics.landing.feature-image3'],
            imageText: this.$i18n.t(
              'telematics.landing.features.feature3.imageText',
            ),
            imageAlt: 'lane warning system  ',
          },
          {
            id: 4,
            title: this.$i18n.t('telematics.landing.features.feature4.title'),
            icon: 'globals/eye-full',
            content: this.$i18n.t(
              'telematics.landing.features.feature4.content',
            ),
            links: [
              {
                id: 1,
                label: this.$i18n.t(
                  'telematics.landing.features.feature4.link1.label',
                ),
                link: this.$i18n.t(
                  'telematics.landing.features.feature4.link1.link',
                ),
              },
              {
                id: 2,
                label: this.$i18n.t(
                  'telematics.landing.features.feature4.link2.label',
                ),
                link: this.$i18n.t(
                  'telematics.landing.features.feature4.link2.link',
                ),
              },
            ],
            image: this.pageFiles['telematics.landing.feature-image4'],
            imageAlt: 'personal trackers',
          },
        ],
      }
    },
    products() {
      return {
        main: {
          title: this.$i18n.t('telematics.landing.tabs.main.title'),
        },
        tabs: [
          {
            id: 1,
            title: this.$i18n.t('telematics.landing.tabs.trackers.title'),
            dynamicClass: 'md:grid-cols-2',
            items: [
              {
                id: 1,
                title: this.$i18n.t(
                  'telematics.landing.tabs.trackers.item1.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.trackers.item1.content',
                ),
                image: this.pageFiles['telematics.landing.tab1.image1'],
                thumb: '840xauto',
                imageWidth: 618,
                imageHeight: 412,
                category: 'trackers',
                link: this.$i18n.t(
                  'telematics.landing.tabs.trackers.item1.link',
                ),
              },
              {
                id: 2,
                title: this.$i18n.t(
                  'telematics.landing.tabs.trackers.item2.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.trackers.item2.content',
                ),
                image: this.pageFiles['telematics.landing.tab1.image2'],
                thumb: '840xauto',
                imageWidth: 618,
                imageHeight: 412,
                category: 'trackers',
                link: this.$i18n.t(
                  'telematics.landing.tabs.trackers.item2.link',
                ),
              },
              {
                id: 3,
                title: this.$i18n.t(
                  'telematics.landing.tabs.trackers.item3.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.trackers.item3.content',
                ),
                image: this.pageFiles['telematics.landing.tab1.image3'],
                thumb: '840xauto',
                imageWidth: 618,
                imageHeight: 412,
                category: 'trackers',
                link: this.$i18n.t(
                  'telematics.landing.tabs.trackers.item3.link',
                ),
              },
              {
                id: 4,
                title: this.$i18n.t(
                  'telematics.landing.tabs.trackers.item4.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.trackers.item4.content',
                ),
                image: this.pageFiles['telematics.landing.tab1.image4'],
                thumb: '840xauto',
                imageWidth: 618,
                imageHeight: 412,
                category: 'trackers',
                link: this.$i18n.t(
                  'telematics.landing.tabs.trackers.item4.link',
                ),
              },
            ],
          },
          {
            id: 2,
            title: this.$i18n.t('telematics.landing.tabs.can-obd-data.title'),
            dynamicClass: 'md:grid-cols-2',
            items: [
              {
                id: 1,
                title: this.$i18n.t(
                  'telematics.landing.tabs.can-obd-data.item1.title',
                ),
                dynamicClass: 'col-span-full',
                content: this.$i18n.t(
                  'telematics.landing.tabs.can-obd-data.item1.content',
                ),
                image: this.pageFiles['telematics.landing.tab2.image1'],
                thumb: '1200xauto',
                imageWidth: 1260,
                imageHeight: 412,
                category: 'can_obd_data',
                link: this.$i18n.t(
                  'telematics.landing.tabs.can-obd-data.item1.link',
                ),
              },
              {
                id: 2,
                title: this.$i18n.t(
                  'telematics.landing.tabs.can-obd-data.item2.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.can-obd-data.item2.content',
                ),
                image: this.pageFiles['telematics.landing.tab2.image2'],
                thumb: '840xauto',
                imageWidth: 618,
                imageHeight: 412,
                category: 'can_obd_data',
                link: this.$i18n.t(
                  'telematics.landing.tabs.can-obd-data.item2.link',
                ),
              },
              {
                id: 3,
                title: this.$i18n.t(
                  'telematics.landing.tabs.can-obd-data.item3.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.can-obd-data.item3.content',
                ),
                image: this.pageFiles['telematics.landing.tab2.image3'],
                thumb: '840xauto',
                imageWidth: 618,
                imageHeight: 412,
                category: 'can_obd_data',
                link: this.$i18n.t(
                  'telematics.landing.tabs.can-obd-data.item3.link',
                ),
              },
            ],
          },
          {
            id: 3,
            title: this.$i18n.t('telematics.landing.tabs.assets.title'),
            items: [
              {
                id: 1,
                title: this.$i18n.t(
                  'telematics.landing.tabs.assets.item1.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.assets.item1.content',
                ),
                image: this.pageFiles['telematics.landing.tab3.image1'],
                thumb: '1200xauto',
                imageWidth: 1260,
                imageHeight: 412,
                category: 'video_telematics',
                link: this.$i18n.t('telematics.landing.tabs.assets.item1.link'),
              },
              {
                id: 2,
                title: this.$i18n.t(
                  'telematics.landing.tabs.assets.item2.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.assets.item2.content',
                ),
                image: this.pageFiles['telematics.landing.tab3.image2'],
                thumb: '1200xauto',
                imageWidth: 1260,
                imageHeight: 412,
                category: 'video_telematics',
                link: this.$i18n.t('telematics.landing.tabs.assets.item2.link'),
              },
            ],
          },
          {
            id: 4,
            title: this.$i18n.t(
              'telematics.landing.tabs.video-telematics.title',
            ),
            items: [
              {
                id: 1,
                title: this.$i18n.t(
                  'telematics.landing.tabs.video-telematics.item1.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.video-telematics.item1.content',
                ),
                image: this.pageFiles['telematics.landing.tab4.image1'],
                thumb: '1200xauto',
                imageWidth: 1260,
                imageHeight: 412,
                category: 'video_telematics',
                link: this.$i18n.t(
                  'telematics.landing.tabs.video-telematics.item1.link',
                ),
              },
              {
                id: 2,
                title: this.$i18n.t(
                  'telematics.landing.tabs.video-telematics.item2.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.video-telematics.item2.content',
                ),
                image: this.pageFiles['telematics.landing.tab4.image2'],
                thumb: '1200xauto',
                imageWidth: 1260,
                imageHeight: 412,
                category: 'video_telematics',
                link: this.$i18n.t(
                  'telematics.landing.tabs.video-telematics.item2.link',
                ),
              },
            ],
          },
          {
            id: 5,
            title: this.$i18n.t('telematics.landing.tabs.accessories.title'),
            dynamicClass: 'md:grid-cols-2',
            items: [
              {
                id: 1,
                title: this.$i18n.t(
                  'telematics.landing.tabs.accessories.item1.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.accessories.item1.content',
                ),
                image: this.pageFiles['telematics.landing.tab5.image1'],
                thumb: '840xauto',
                imageWidth: 618,
                imageHeight: 412,
                category: 'accessories',
                link: this.$i18n.t(
                  'telematics.landing.tabs.accessories.item1.link',
                ),
              },
              {
                id: 2,
                title: this.$i18n.t(
                  'telematics.landing.tabs.accessories.item2.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.accessories.item2.content',
                ),
                image: this.pageFiles['telematics.landing.tab5.image2'],
                thumb: '840xauto',
                imageWidth: 618,
                imageHeight: 412,
                category: 'accessories',
                link: this.$i18n.t(
                  'telematics.landing.tabs.accessories.item2.link',
                ),
              },
              {
                id: 3,
                title: this.$i18n.t(
                  'telematics.landing.tabs.accessories.item3.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.accessories.item3.content',
                ),
                image: this.pageFiles['telematics.landing.tab5.image3'],
                thumb: '840xauto',
                imageWidth: 618,
                imageHeight: 412,
                category: 'accessories',
                link: this.$i18n.t(
                  'telematics.landing.tabs.accessories.item3.link',
                ),
              },
              {
                id: 4,
                title: this.$i18n.t(
                  'telematics.landing.tabs.accessories.item4.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.accessories.item4.content',
                ),
                image: this.pageFiles['telematics.landing.tab5.image4'],
                thumb: '840xauto',
                imageWidth: 618,
                imageHeight: 412,
                category: 'accessories',
                link: this.$i18n.t(
                  'telematics.landing.tabs.accessories.item4.link',
                ),
              },
            ],
          },
          {
            id: 6,
            title: this.$i18n.t('telematics.landing.tabs.solutions.title'),
            dynamicClass: 'md:grid-cols-6',
            items: [
              {
                id: 4,
                title: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item4.title',
                ),
                content: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item4.content',
                ),
                image: this.pageFiles['telematics.landing.tab6.image4'],
                dynamicClass: 'col-span-3',
                thumb: '840xauto',
                imageWidth: 618,
                imageHeight: 412,
                category: 'solutions',
                link: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item4.link',
                ),
              },
              {
                id: 1,
                title: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item1.title',
                ),
                dynamicClass: 'col-span-3',
                content: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item1.content',
                ),
                image: this.pageFiles['telematics.landing.tab6.image1'],
                thumb: '840xauto',
                imageWidth: 618,
                imageHeight: 412,
                category: 'solutions',
                link: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item1.link',
                ),
              },
              {
                id: 5,
                title: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item5.title',
                ),
                dynamicClass: 'col-span-2',
                content: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item5.content',
                ),
                image: this.pageFiles['telematics.landing.tab6.image5'],
                thumb: '840xauto',
                imageWidth: 404,
                imageHeight: 245,
                category: 'solutions',
                link: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item5.link',
                ),
              },
              {
                id: 2,
                title: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item2.title',
                ),
                dynamicClass: 'col-span-2',
                content: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item2.content',
                ),
                image: this.pageFiles['telematics.landing.tab6.image2'],
                thumb: '840xauto',
                imageWidth: 404,
                imageHeight: 245,
                category: 'solutions',
                link: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item2.link',
                ),
              },
              {
                id: 3,
                title: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item3.title',
                ),
                dynamicClass: 'col-span-2',
                content: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item3.content',
                ),
                image: this.pageFiles['telematics.landing.tab6.image3'],
                thumb: '840xauto',
                imageWidth: 404,
                imageHeight: 245,
                category: 'solutions',
                link: this.$i18n.t(
                  'telematics.landing.tabs.solutions.item3.link',
                ),
              },
            ],
          },
        ],
      }
    },
    // quiz() {
    //   return {
    //     main: {
    //       title: 'WHAT DEVICE IS RIGHT FOR YOU?',
    //       content: 'Find out in a few quick steps by going through our quiz.',
    //       ctaText: 'FIND OUT NOW',
    //       ctaLink: '#'
    //     }
    //   }
    // },
    trackers() {
      return {
        title: this.$i18n.t('telematics.landing.trackers.title'),
        image: this.pageFiles['telematics.landing.trackers.image'],
        imageAlt: 'waterproof tracker collection',
        label: '',
        content: this.$i18n.t('telematics.landing.trackers.content'),
        items: [
          this.$i18n.t('telematics.landing.trackers.feature1'),
          this.$i18n.t('telematics.landing.trackers.feature2'),
          this.$i18n.t('telematics.landing.trackers.feature3'),
          this.$i18n.t('telematics.landing.trackers.feature4'),
        ],
        ctaText: this.$i18n.t('telematics.landing.trackers.ctaText'),
        ctaLink: this.$i18n.t('telematics.landing.trackers.ctaLink'),
      }
    },
    journals() {
      return [
        {
          id: 1,
          title: this.$i18n.t('telematics.landing.catalog.title'),
          date: this.$i18n.t('telematics.landing.catalog.date'),
          link: this.$i18n.t('telematics.landing.catalog.external.pdfFile'),
          image: this.pageFiles['telematics.landing.journals.image1'],
        },
        {
          id: 2,
          title: this.$i18n.t('telematics.landing.journal.title'),
          date: this.$i18n.t('telematics.landing.journal.date'),
          link: this.$i18n.t('telematics.landing.journal.external.pdfFile'),
          image: this.pageFiles['telematics.landing.journals.image2'],
        },
      ]
    },
    promotion() {
      return [
        {
          id: 1,
          title: this.$i18n.t('telematics.landing.promotion.item1.title'),
          content: this.$i18n.t('telematics.landing.promotion.item1.content'),
        },
        {
          id: 2,
          title: this.$i18n.t('telematics.landing.promotion.item2.title'),
          content: this.$i18n.t('telematics.landing.promotion.item2.content'),
        },
        {
          id: 3,
          title: this.$i18n.t('telematics.landing.promotion.item3.title'),
          content: this.$i18n.t('telematics.landing.promotion.item3.content'),
        },
      ]
    },
    newsletter() {
      return {
        image: this.pageFiles['telematics.landing.newsletter.image'],
        title: this.$i18n.t('telematics.landing.newsletter.title'),
        content: this.$i18n.t('telematics.landing.newsletter.content'),
        ctaText: this.$i18n.t('telematics.landing.newsletter.ctaText'),
      }
    },
    formattedNews() {
      const news = this.news.reduce(
        (previous, currentValue) => [
          ...previous,
          {
            link: currentValue.current_url.full_url,
            date: currentValue.date,
            title: currentValue.title,
            branch: 'telematics',
            excerpt: currentValue.excerpt,
            image: currentValue.media,
          },
        ],
        [],
      )

      return {
        main: {
          title: this.$i18n.t('telematics.landing.news.main.title'),
          linkText: this.$i18n.t('telematics.landing.news.main.linkText'),
          link: this.$i18n.t('telematics.landing.news.main.link'),
        },
        news,
      }
    },
    whyTelematics() {
      return {
        main: {
          title: this.$i18n.t('telematics.landing.why-telematics.main.title'),
        },
        cards: [
          {
            id: 1,
            title: this.$i18n.t(
              'telematics.landing.why-telematics.cards.card1.title',
            ),
            icon: this.$i18n.t(
              'telematics.landing.why-telematics.cards.card1.icon',
            ),
            content: this.$i18n.t(
              'telematics.landing.why-telematics.cards.card1.content',
            ),
            items: [
              {
                id: 1,
                content: this.$i18n.t(
                  'telematics.landing.why-telematics.cards.card1.item1.content',
                ),
              },
              {
                id: 2,
                content: this.$i18n.t(
                  'telematics.landing.why-telematics.cards.card1.item2.content',
                ),
              },
              {
                id: 3,
                content: this.$i18n.t(
                  'telematics.landing.why-telematics.cards.card1.item3.content',
                ),
              },
            ],
          },
          {
            id: 2,
            title: this.$i18n.t(
              'telematics.landing.why-telematics.cards.card2.title',
            ),
            icon: this.$i18n.t(
              'telematics.landing.why-telematics.cards.card2.icon',
            ),
            content: this.$i18n.t(
              'telematics.landing.why-telematics.cards.card2.content',
            ),
            items: [
              {
                id: 1,
                content: this.$i18n.t(
                  'telematics.landing.why-telematics.cards.card2.item1.content',
                ),
              },
              {
                id: 2,
                content: this.$i18n.t(
                  'telematics.landing.why-telematics.cards.card2.item2.content',
                ),
              },
              {
                id: 3,
                content: this.$i18n.t(
                  'telematics.landing.why-telematics.cards.card2.item3.content',
                ),
              },
            ],
          },
          {
            id: 3,
            title: this.$i18n.t(
              'telematics.landing.why-telematics.cards.card3.title',
            ),
            icon: this.$i18n.t(
              'telematics.landing.why-telematics.cards.card3.icon',
            ),
            content: this.$i18n.t(
              'telematics.landing.why-telematics.cards.card3.content',
            ),
            items: [
              {
                id: 1,
                content: this.$i18n.t(
                  'telematics.landing.why-telematics.cards.card3.item1.content',
                ),
              },
              {
                id: 2,
                content: this.$i18n.t(
                  'telematics.landing.why-telematics.cards.card3.item2.content',
                ),
              },
              {
                id: 3,
                content: this.$i18n.t(
                  'telematics.landing.why-telematics.cards.card3.item3.content',
                ),
              },
            ],
          },
        ],
      }
    },
  },
  created() {
    this.pageFiles = this.$store?.state?.page?.content?.files || {}
  },
  methods: {
    async loadMap() {
      const {
        data: { contacts },
      } = await this.$gqlQuery({
        query: ContactsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          domainId: this.$config.domainId,
          languageCode: this.$i18n.locale,
        },
      })
      const formattedCountries = formatRawMapResponse(contacts)
      this.countries = formattedCountries

      if (!Array.isArray(formattedCountries) && formattedCountries.length === 0)
        return

      this.$store.commit(
        'contacts/setOffices',
        formattedCountries
          .flatMap((countryItem) => countryItem.offices)
          .map((countryItem) => ({
            id: countryItem.id,
            title: countryItem.title_translated,
            address: countryItem.address_translated,
            value: countryItem.value,
          })),
      )
    },
  },
}
