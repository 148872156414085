
import { VueAgile } from 'vue-agile'
export default {
  name: 'HeroTwentyTwo',
  components: {
    VueAgile,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sliders_options: {
        slidesToShow: 1,
        navButtons: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 8000,
      },
    }
  },
}
